<template>
  <tr>
    <td class="align-middle">
      <div class="d-flex align-items-center">
        <div @click="showExerciseLibraryModal = true" role="button" class="flex-grow-1">
          <div>{{ item.name }}</div>
          <div
            v-if="getDescription(item)"
            v-html="getDescription(item)"
            class="small text-muted"
          ></div>
        </div>
        <IconButton
          @click="showPreviewModal = true"
          v-if="item.videoUrl"
          :icon="'video'"
          :disabled="isDeleting"
          class="btn btn-sm btn-primary ms-2"
        ></IconButton>
        <IconButton
          @click="showConfirmModal = true"
          :icon="'trash-alt'"
          :disabled="isDeleting"
          :isLoading="isDeleting"
          class="btn btn-sm btn-danger ms-2"
        ></IconButton>
      </div>
    </td>

    <portal to="modal">
      <transition name="modal">
        <AdminLibraryModal
          @addOrUpdate="$emit('addOrUpdate', $event); showExerciseLibraryModal = false"
          @close="showExerciseLibraryModal = false"
          v-model="showExerciseLibraryModal"
          v-if="showExerciseLibraryModal"
          :exercise="item"
        ></AdminLibraryModal>
      </transition>
    </portal>

    <portal to="modal">
      <transition name="modal">
        <ConfirmModal
          @confirm="deleteItem"
          @close="showConfirmModal = false"
          v-if="showConfirmModal"
          v-model="showConfirmModal"
        ></ConfirmModal>
      </transition>
    </portal>

    <portal to="modal">
      <transition name="modal">
        <ResourcePreviewModal
          @close="showPreviewModal = false"
          v-if="showPreviewModal"
          v-model="showPreviewModal"
          :url="item.videoUrl"
        ></ResourcePreviewModal>
      </transition>
    </portal>
  </tr>
</template>

<script>
import { httpPost } from '@/core/http';

export default {
  name: 'AdminLibraryManagerItem',
  components: {
    IconButton: () => import('@/components/button/IconButton'),
    AdminLibraryModal: () => import('@/components/admin/AdminLibraryModal'),
    ConfirmModal: () => import('@/components/modal/ConfirmModal'),
    ResourcePreviewModal: () => import('@/components/modal/ResourcePreviewModal'),
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    async deleteItem() {
      this.showConfirmModal = false;
      this.isDeleting = true;
      try {
        const { exerciseLibraryId } = this.item;
        const res = await httpPost('/admin/library/delete', { exerciseLibraryId });
        this.$emit('delete', res.data);
      } catch (err) {
        this.$store.dispatch('addSystemError', err, { root: true });
      } finally {
        this.isDeleting = false;
      }
    },
    getDescription(item) {
      const { description } = item;
      if (!description || description === '') return null;
      return this.$options.filters.nl2br(description);
    },
  },
  data() {
    return {
      showExerciseLibraryModal: false,
      showConfirmModal: false,
      showPreviewModal: false,
      isDeleting: false,
    };
  },
};
</script>
